import { GET_USER_ACCOUNT_DATA } from '../helpers/constants';
export default function useraccountReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER_ACCOUNT_DATA:
      return Object.assign({}, state, { ...action.data });

    default:
      return state;
  }
}
