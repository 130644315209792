import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import App from './components/App';
import configureStore from './configureStore';
import { MDTApp } from 'mdt-core-components/src/actions/bootAction';
import './index.css';
const history = createHistory();
const store = configureStore(history);

const render = App =>
  new Promise(function(resolveStore, reject) {
    store.dispatch(MDTApp.initBootstrap(resolveStore));
  }).then(function(data) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root')
    );
  });

render(App);
