import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switcher from './Switcher';
import { I18nextProvider } from 'react-i18next';
import i18n from 'mdt-core-components/src/helpers/i18n';
import { MDTNavigation, MDTlayout, SnackBar } from 'mdt-core-components';
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="MDTApp notranslate">
          <I18nextProvider i18n={i18n}>
            <MDTlayout themeLayout={`${this.props.themeLayout} new-navigation`}>
              <MDTNavigation />
              <div className="mdt-content" style={{ minHeight: '100%' }}>
                <Switcher />
                <SnackBar />
              </div>
            </MDTlayout>
          </I18nextProvider>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    themeLayout: state.userDomInfo.theme || '',
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
