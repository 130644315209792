export default (state = 'LOGIN', action = {}) =>
  components[action.type] || state;

const components = {
  HOME: 'login',
  LOGIN: 'login',
  PORTAL: 'portal',
  FORGOTPASSWORD: 'forgotpassword',
  REQUESTACCESS: 'requestAccess',
  REQUESTADDITIONALACCESS: 'requestAdditionalAccess',
  NOTIFICATIONDETAILS: 'notificationDetails',
  USERPREFERENCES: 'userPreferences',
  ADMIN: 'Admin',
  MANAGEACTIVECUSTOMERS: 'manageActiveCustomers',
  MANAGEMYACCOUNTS: 'manageMyAccounts',
  CONTACTUS: 'contactUs',
  ACCOUNTSETUP: 'accountSetup',
  TERMSANDCONDITIONS: 'termsAndConditions',
  PRODUCTDETAILS: 'productDetails',
  SEARCHPRODUCT: 'searchProduct',
  VIEWCATALOG : 'viewCatalog',
  MECAPP: 'mecApp',
  DOWNLOADCATALOG: 'downloadCatalog'
};
