import { reducers } from 'mdt-core-components/src/reducers';

import page from './page';
import user from './user';
import title from './title';
import userShippingPreferences from './useraccount';

// NOTE: Expose all the reducers from mdt-core-components, and add the reducers from cent
// Cent shouldn't know where the reducers from mdt-core-components are located 20190827:Alevale
export default Object.assign(reducers, {
  page,
  user,
  title,
  userShippingPreferences,
});
