import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, Transition } from 'transition-group';

const Switcher = ({ page, direction, isLoading }) => {
  const UniversalComponent = lazy(() => import(`./${page}`));

  return (
    <TransitionGroup className={`switcher ${direction}`}>
      <Transition key={page}>
        <Suspense
          fallback={
            <div className="spinner">
              <div />
            </div>
          }
        >
          <UniversalComponent />
        </Suspense>
      </Transition>
    </TransitionGroup>
  );
};

const isListFetching = page => {
  return page === 'List';
};

const mapState = ({ page, direction }) => {
  const isLoading = isListFetching(page);
  return { page, direction, isLoading };
};

export default connect(mapState)(Switcher);
