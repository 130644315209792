import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRoutes } from 'redux-first-router';
import routesMap from './routesMap';
import options from './options';
import reducers from './reducers';
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';

export default history => {
  const loggerMiddleware = createLogger;
  const middlewares = [thunk, loggerMiddleware];

  const { reducer, middleware, enhancer } = connectRoutes(
    history,
    routesMap,
    options
  );
  middlewares.push(middleware);

  const rootReducer = combineReducers({ ...reducers, location: reducer });

  //const middlewares = applyMiddleware(middleware)
  const enhancers = composeEnhancers(enhancer, applyMiddleware(...middlewares));

  return createStore(rootReducer, enhancers);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;
